const baseUrl = process.env.REACT_APP_BASE_URL;

const Endpoints = {
    ChangePw: baseUrl + '/user/change-pw',
    ForgotPassword: baseUrl + '/user/forgot-pw',
    Invoice: baseUrl + '/invoice',
    ReimbursablesInvoice: baseUrl + '/invoice/reimbursables',
    AuthorizeQuickBooks: baseUrl + '/auth/qb',
    UpdateQbAuth: baseUrl + '/auth/qb/complete',
    SubmitInvoice: baseUrl + '/invoice/submit',
    MarkInvoiceSent: baseUrl + '/invoice/sent',
    PreSignedUrl: baseUrl + '/uploads/presigned-url',
    Profile: baseUrl + '/profile',
    Projects: baseUrl + '/projects',
    RefreshAuthToken: baseUrl + '/user/refresh',
    ResetUserPw: baseUrl + '/users/reset-pw',
    SaveUploadUrls: baseUrl + '/uploads/urls',
    Reimbursables: baseUrl + '/reimbursables',
    SystemSettings: baseUrl + '/system-settings',
    TimeLogs: baseUrl + '/timelogs',
    Users: baseUrl + '/users',
}

export { Endpoints };