import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as actions from '../actions';
import { urls } from './index';
import ApiHelper from '../utilities/apiHelper';
import { Endpoints } from '../Constants';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseDropDown, BaseInput, ConditionalDOM, ReimbursableEditor, TimeLogEditor } from '../components';

const ActionCell = ({item, pastInvoices, onUpdated}) => {
  const [loading, setLoading] = useState(false)

  const onClick = async (event) => {
    if (item.inquickbooks) {
      return
    }

    event.stopPropagation()
    setLoading(true)

    const response = await ApiHelper.put(Endpoints.MarkInvoiceSent, {
      idinvoice: item.idinvoice,
      isreimbursables: item.isreimbursables,
      idlist: item.timelogs
    })

    if (!response.success) {
      alert('There was an unknown error while marking this invoice as sent.')
      return
    }
    
    // Set inquickbooks locally and avoid reloading page?
    let list = []
    for (let invoice of pastInvoices) {
      if (invoice.idinvoice !== item.idinvoice) {
        list.push(invoice)
        continue
      }
      invoice.inquickbooks = true
      list.push(invoice)
    }
    onUpdated(list)

    setLoading(false)
  }

  const getContent = () => {
    if (loading) {
      return (
        <div className='small-loader'>
            <svg className='circular' viewBox='25 25 50 50'>
              <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10'/>
            </svg>
          </div> 
      )
    }

    if (item.inquickbooks) {
      return (
        <p className='grid-text'>Yes</p>
      )
    }

    return (
      <p style = {{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>Add</p>
    )
  }

  return (
    <div className='grid-cell' style={{ width: '80px' }} onClick={onClick}>
      {getContent()}
    </div >
  )
}

class ManageInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enddate: '',
      invoice: {},
      invoiceAmount: 0,
      invoiceDescription: '',
      invoiceReimbursables: [],
      invoiceTimeLogs: [],
      pastInvoices: [],
      selectedProject: {},
      startdate: '',
      selectedReimbursable: {},
      selectedTimeLog: {},
      showForm: false,
      showCreateForm: true,
      unbilledReimbursables: [],
      unbilledTimeLogs: []
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()) {
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed) {
        this.props.history.push(urls.login);
        return;
      }
    }
    this.ensureDataForPage();
  }

  //METHODS
  ensureDataForPage = async () => {
    if (!this.props.projects || this.props.projects.length < 1) {
      this.props.showLoading();
      let response = await ApiHelper.get(Endpoints.Projects);
      if (response.data) {
        this.props.setProjectList(response.data);
      }
    }
    if (!this.props.users || this.props.users.length < 1) {
      this.props.showLoading();
      let response = await ApiHelper.get(Endpoints.Users);
      if (response.data) {
        this.props.setUserList(response.data);
      }
    }

    this.props.closeLoading();
  }
  viewPastInvoices = async () => {
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Invoice + '?recent=1');
    if (response.success) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      let recentFirst = [...response.data].reverse()
      console.log(recentFirst)
      this.setPastInvoices(recentFirst);
    } else {
      alert('No Invoices to show.');
    }
    this.props.closeLoading();
  }
  setPastInvoices = (list) => {
    let pastInvoices = [];

    for (let invoice of list) {
      invoice.friendlydate = '';
      invoice.description = invoice.description ? invoice.description : 'No description.';
      pastInvoices.push(invoice);
    }

    this.setState({ pastInvoices, invoice: {}, showForm: false, invoiceTimeLogs: [], invoiceReimbursables: [] });
  }
  clearInvoiceHistoryClicked = () => {
    this.setState({ pastInvoices: [], showForm: false, showCreateForm: true, selectedProject: {} });
  }
  cancelInvoice = () => {
    if (!window.confirm('Are you sure that you\'d like to start over?')) {
      return;
    }
    window.location.reload();
  }
  checkForUnbilledTimeLogs = async () => {
    if (!this.state.selectedProject || !this.state.selectedProject.idproject) {
      alert('Please select a project to begin.');
      return;
    }
    if (this.state.startdate.length < 1 || this.state.enddate.length < 1) {
      alert('Please fill in the dates to begin.');
      return;
    }

    this.props.showLoading();

    let unbilledTimeLogs = await this.getUnbilledTimeLogs();
    if (unbilledTimeLogs?.length > 0) {
      this.setState({ unbilledTimeLogs });
      this.props.closeLoading();
      return;
    }
    this.startInvoice(false);
  }
  checkForUnbilledReimbursables = async () => {
    if (!this.state.selectedProject || !this.state.selectedProject.idproject) {
      alert('Please select a project to begin.');
      return;
    }
    if (this.state.startdate.length < 1 || this.state.enddate.length < 1) {
      alert('Please fill in the dates to begin.');
      return;
    }

    this.props.showLoading();

    let unbilledReimbursables = await this.getUnbilledReimbursables();
    if (unbilledReimbursables?.length > 0) {
      this.setState({ unbilledReimbursables });
      this.props.closeLoading();
      return;
    }
    this.startReimbursablesInvoice(false);
  }
  startInvoice = async (addUnbilled) => {
    if (!this.state.selectedProject || !this.state.selectedProject.idproject) {
      alert('Please select a project to begin.');
      return;
    }
    if (this.state.startdate.length < 1 || this.state.enddate.length < 1) {
      alert('Please fill in the dates to begin.');
      return;
    }

    this.props.showLoading();

    let endpoint = Endpoints.Invoice + '?project=' + this.state.selectedProject.idproject + '&start=' + this.state.startdate + '&end=' + this.state.enddate + '&all=1&asc=1';
    let body = addUnbilled ? { unbilledIds: this.state.unbilledTimeLogs.map(t => t.idtimelog) } : null;
    let response = await ApiHelper.post(endpoint, body);

    if (response.success) {
      this.setInvoice(response.data);
    } else if (response.status === 404) {
      alert('No time logs were found for ' + this.state.selectedProject.title + ' in the given time period.');
    } else {
      alert('Something went wrong while trying to begin the invoice.');
    }

    this.props.closeLoading();
  }
  startReimbursablesInvoice = async (addUnbilled) => {
    if (!this.state.selectedProject || !this.state.selectedProject.idproject) {
      alert('Please select a project to begin.');
      return;
    }
    if (this.state.startdate.length < 1 || this.state.enddate.length < 1) {
      alert('Please fill in the dates to begin.');
      return;
    }

    this.props.showLoading();

    let endpoint = Endpoints.ReimbursablesInvoice + '?project=' + this.state.selectedProject.idproject + '&start=' + this.state.startdate + '&end=' + this.state.enddate + '&all=1&asc=1';
    let body = addUnbilled ? { unbilledIds: this.state.unbilledReimbursables.map(r => r.idreimbursable) } : null;
    let response = await ApiHelper.post(endpoint, body)

    if (response.success) {
      this.setReimbursablesInvoice(response.data);
    } else if (response.status === 404) {
      alert('No reimbursables were found for ' + this.state.selectedProject.title + ' in the given time period.');
    } else {
      alert('Something went wrong while trying to begin the invoice.');
    }

    this.props.closeLoading();
  }
  setInvoice = (invoice) => {
    if (!invoice) {
      return;
    }

    let list = this.attachInitialsProp(invoice.timelogs);

    this.setState({
      invoiceTimeLogs: list,
      invoiceReimbursables: [],
      invoiceAmount: invoice.amount,
      invoice,
      showForm: true,
      showCreateForm: false,
      unbilledTimeLogs: []
    });
  }
  attachInitialsProp = (list) => {
    if (!list) {
      return null;
    }

    for (let item of list) {
      let user = this.props.users.filter(el => el.iduser === item.iduser)[0];
      item.initials = user.initials;
    }

    return list;
  }
  setReimbursablesInvoice = (invoice) => {
    if (!invoice) {
      return;
    }

    let list = this.attachInitialsProp(invoice.reimbursables);

    this.setState({
      invoiceTimeLogs: [],
      invoiceReimbursables: list,
      invoiceAmount: invoice.amount,
      invoice,
      showForm: true,
      showCreateForm: false,
      unbilledReimbursables: []
    });
  }
  getUnbilledTimeLogs = async () => {
    //assume valid params
    let response = await ApiHelper.get(Endpoints.TimeLogs + '?project=' + this.state.selectedProject.idproject + '&end=' + this.state.startdate + '&isbilled=0');
    return this.attachInitialsProp(response.data);
  }
  getUnbilledReimbursables = async () => {
    //assume valid params
    let response = await ApiHelper.get(Endpoints.Reimbursables + '?project=' + this.state.selectedProject.idproject + '&end=' + this.state.startdate + '&isbilled=0&all=1');
    return this.attachInitialsProp(response.data);
  }
  invoiceItemClicked = (invoice) => {
    this.setState({ invoice, invoiceAmount: invoice.amount, invoiceDescription: invoice.description, pastInvoices: [], showForm: true });
  }
  loadTimeLogList = async () => {
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.TimeLogs + '?list=' + JSON.stringify(this.state.invoice.timelogs) + '&all=1&asc=1');
    if (response.success) {
      this.setState({ invoiceTimeLogs: response.data });
    } else {
      alert('Something went wrong while trying to retrieve Time Logs for this Invoice.');
    }
    this.props.closeLoading();
  }
  loadReimbursablesList = async () => {
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Reimbursables + '?list=' + JSON.stringify(this.state.invoice.reimbursables));
    if (response.success) {
      console.log(response.data);
      this.setState({ invoiceReimbursables: response.data });
    } else {
      alert('Something went wrong while trying to retrieve Reimbursables for this Invoice.');
    }
    this.props.closeLoading();
  }
  sendNotesToInvoice = () => {
    if (!window.confirm('Are you sure you want to send each Time Log note to the invoice text area? This will overwrite any text currently in the text area.')) {
      return;
    }
    let invoiceDescription = '';
    if (this.state.invoiceTimeLogs.length < 1) {
      this.setState({ invoiceDescription: 'No Time Log notes.' });
      return;
    }

    for (let log of this.state.invoiceTimeLogs) {
      if (!log.notes || log.notes.length < 1) {
        continue;
      }
      invoiceDescription += log.notes + '\n';
    }

    //NOTE: Need to skip when a past invoice is loaded, as this value is not stored in the db...
    if (this.state.invoice.rates) {
      let rateKeys = Object.keys(this.state.invoice.rates);
      for (let key of rateKeys) {
        invoiceDescription += '\n' + Helpers.FormatHours(this.state.invoice.rates[key]) + ' Hours @ ' + Helpers.FormatDollars(key, true);
      }
    }

    this.setState({ invoiceDescription, invoice: { ...this.state.invoice, description: invoiceDescription } });
  }
  sendReimbursableDescriptionsToInvoice = () => {
    if (!window.confirm('Are you sure you want to send each Reimbursable description to the invoice text area? This will overwrite any text currently in the text area.')) {
      return;
    }
    let invoiceDescription = '';
    if (this.state.invoiceReimbursables.length < 1) {
      this.setState({ invoiceDescription: 'No Reimbursable descriptions.' });
      return;
    }

    for (let item of this.state.invoiceReimbursables) {
      if (!item.description || item.description.length < 1) {
        continue;
      }
      invoiceDescription += item.description + '\n';
    }

    if (invoiceDescription.length < 1) {
      invoiceDescription = 'No Reimbursable descriptions.';
    }

    this.setState({ invoiceDescription });
  }
  saveInvoiceClicked = async () => {
    this.props.showLoading();
    let timelogids = [];
    for (let log of this.state.invoiceTimeLogs) {
      timelogids.push(log.idtimelog);
    }

    let body = { ...this.state.invoice, timelogids }

    let response = await ApiHelper.put(Endpoints.Invoice, body);
    if (response.success) {
      alert('The Invoice was updated.');
    } else {
      alert('Something went wrong while trying to save this Invoice.');
    }
    this.props.closeLoading();
  }
  deleteInvoiceClicked = async () => {
    if (!this.state.invoice.idinvoice) {
      alert('Invalid Invoice.');
      return;
    }
    if (!window.confirm('This will delete the Invoice in Haas Time, but not in QuickBooks.')) {
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.delete(Endpoints.Invoice + '?id=' + this.state.invoice.idinvoice);
    if (response.success) {
      alert('The Invoice was deleted.');
      this.viewPastInvoices();
    } else {
      alert('Something went wrong while trying to delete this Invoice.')
    }
    this.props.closeLoading();
  }
  timeLogClicked = (selectedTimeLog) => {
    if (!selectedTimeLog || !selectedTimeLog.idtimelog) {
      return;
    }

    this.setState({ selectedTimeLog });
  }
  reimbursableClicked = (selectedReimbursable) => {
    if (!selectedReimbursable || !selectedReimbursable.idreimbursable) {
      return;
    }

    this.setState({ selectedReimbursable });
  }
  unselectTimeLog = () => {
    this.setState({ selectedTimeLog: null });
  }
  unselectReimbursable = () => {
    this.setState({ selectedReimbursable: null });
  }
  reloadList = () => {
    this.setState({ selectedTimeLog: null });
    alert('Need to reload list of time logs independently of everything else.');
  }

  //EVENT HANDLERS
  onchange = (e) => {
    if (!e) { return; }
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdates = (name, value) => {
    if (name.indexOf(':') > 0) {
      let parts = name.split(':');
      let objName = parts[0];
      let propName = parts[1];
      this.setState(prevState => {
        prevState[objName][propName] = value;
        return { [objName]: prevState[objName] };
      })
    } else {
      this.setState({ [name]: value });
    }
  }
  selectedProjectChanged = (selectedProject) => {
    this.setState({ selectedProject });
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <ConditionalDOM render={this.state.pastInvoices.length < 1 && this.state.showCreateForm}>
            <div className='content-card singular-card' style={{ width: '800px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                <div>
                  <p className='page-header'>Invoices</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <BaseButton content='Unbilled Invoices' classes='temp-short-button' onClick={this.viewPastInvoices} />
                </div>
              </div>
              <div style={{ margin: '0px 0px 0px 0px' }}>
                <BaseDropDown items={this.props.projects}
                  emptyText='No Projects to Show'
                  placeholder={this.state.selectedProject.title ? this.state.selectedProject.title : 'Select a project'}
                  titleProp='title'
                  selectionChanged={this.selectedProjectChanged} />
              </div>
              <Row>
                <Col xs='6'>
                  <BaseInput name='startdate' changeHandler={this.handleUpdates} label='Start Date' type='date' required />
                </Col>
                <Col xs='6'>
                  <BaseInput name='enddate' changeHandler={this.handleUpdates} label='End Date' type='date' required />
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs='12' lg='6'>
                  <BaseButton content='Start Invoice' onClick={this.checkForUnbilledTimeLogs} />
                </Col>
                <Col xs='12' lg='6'>
                  <BaseButton content='Start Reimbursables Invoice' onClick={this.checkForUnbilledReimbursables} />
                </Col>
              </Row>
            </div>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.pastInvoices.length < 1 && !this.state.showCreateForm}>
            <div className='content-card singular-card' style={{ width: '800px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                <div>
                  <p className='page-header'>Invoices</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <BaseButton content='Unbilled Invoices' classes='temp-short-button' onClick={this.viewPastInvoices} />
                </div>
              </div>
            </div>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.unbilledTimeLogs.length > 0}>
            <div className='content-card singular-card' style={{ width: '800px', marginBottom: '3em' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                <div>
                  <p className='page-header'>Unbilled Time Logs</p>
                </div>
              </div>
              <div className='grid-row'>
                <div className='grid-cell' style={{ width: '80px' }}><p className='grid-header'>Initials</p></div>
                <div className='grid-cell' style={{ width: '150px' }}><p className='grid-header'>Date</p></div>
                <div className='grid-cell' style={{ width: '120px' }}><p className='grid-header'>Hours</p></div>
                <div className='grid-cell' style={{ width: '500px' }}><p className='grid-header'>Description</p></div>
              </div>
              {
                this.state.unbilledTimeLogs.map((item, i) => (
                  <div key={'item' + item.idtimelog} className='grid-row item-hover' onClick={() => this.timeLogClicked(item)}>
                    <div className='grid-cell' style={{ width: '80px' }}><p className='grid-text'>{item.initials}</p></div>
                    <div className='grid-cell' style={{ width: '150px' }}><p className='grid-text'>{item.friendlydate}</p></div>
                    <div className='grid-cell' style={{ width: '120px' }}><p className='grid-text'>{Helpers.ConvertSecToHrs(item.duration)}</p></div>
                    <div className='grid-cell' style={{ width: '500px' }}><p className='grid-text'>{item.notes}</p></div>
                  </div>
                ))
              }
              <div style={{ borderTop: '1px solid #000000' }}></div>
              <Row style={{ marginTop: '30px' }}>
                <Col xs='12' lg='4'>
                  <BaseButton content='Cancel this Invoice' classes='delete-button' onClick={this.cancelInvoice} />
                </Col>
                <Col xs='12' lg='4'>
                  <BaseButton content='Continue without Adding' onClick={() => this.startInvoice(false)} />
                </Col>
                <Col xs='12' lg='4'>
                  <BaseButton content='Add to Invoice' onClick={() => this.startInvoice(true)} />
                </Col>
              </Row>
            </div>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.unbilledReimbursables.length > 0}>
            <div className='content-card singular-card' style={{ width: '800px', marginBottom: '3em' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                <div>
                  <p className='page-header'>Unbilled Reimbursables</p>
                </div>
              </div>
              <div className='grid-row'>
                <div className='grid-cell' style={{ width: '80px' }}><p className='grid-header'>Initials</p></div>
                <div className='grid-cell' style={{ width: '150px' }}><p className='grid-header'>Date</p></div>
                <div className='grid-cell' style={{ width: '120px' }}><p className='grid-header'>Amount</p></div>
                <div className='grid-cell' style={{ width: '500px' }}><p className='grid-header'>Description</p></div>
              </div>
              {
                this.state.unbilledReimbursables.map((item, i) => (
                  <div key={'item' + item.idreimbursable} className='grid-row item-hover' onClick={() => this.reimbursableClicked(item)}>
                    <div className='grid-cell' style={{ width: '80px' }}><p className='grid-text'>{item.initials}</p></div>
                    <div className='grid-cell' style={{ width: '150px' }}><p className='grid-text'>{item.friendlydate}</p></div>
                    <div className='grid-cell' style={{ width: '120px' }}><p className='grid-text'>{Helpers.FormatDollars(item.amount)}</p></div>
                    <div className='grid-cell' style={{ width: '500px' }}><p className='grid-text'>{item.description}</p></div>
                  </div>
                ))
              }
              <div style={{ borderTop: '1px solid #000000' }}></div>
              <Row style={{ marginTop: '30px' }}>
                <Col xs='12' lg='4'>
                  <BaseButton content='Cancel this Invoice' classes='delete-button' onClick={this.cancelInvoice} />
                </Col>
                <Col xs='12' lg='4'>
                  <BaseButton content='Continue without Adding' onClick={() => this.startReimbursablesInvoice(false)} />
                </Col>
                <Col xs='12' lg='4'>
                  <BaseButton content='Add to Invoice' onClick={() => this.startReimbursablesInvoice(true)} />
                </Col>
              </Row>
            </div>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.pastInvoices.length > 0}>
            <div className='content-card singular-card' style={{ width: '800px', marginBottom: '3em' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                <div>
                  <p className='page-header'>Unbilled Invoices</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <BaseButton content='Create Invoice' classes='temp-short-button' onClick={this.clearInvoiceHistoryClicked} />
                </div>
              </div>
              <div className='grid-row'>
                <div className='grid-cell' style={{ flex: '1.5' }}><p className='grid-header'>Project</p></div>
                <div className='grid-cell' style={{ flex: '1' }}><p className='grid-header'>Date</p></div>
                <div className='grid-cell' style={{ flex: '1' }}><p className='grid-header'>Amount</p></div>
                <div className='grid-cell' style={{ flex: '1' }}><p className='grid-header'>Invoice Period</p></div>
                <div className='grid-cell' style={{ width: '80px' }}><p className='grid-header'>In QB?</p></div>
              </div>
              {
                this.state.pastInvoices.map((item, i) => (
                  <div key={'item' + item.idinvoice} className='grid-row item-hover' onClick={() => this.invoiceItemClicked(item)}>
                    <div className='grid-cell' style={{ flex: '1.5' }}><p className='grid-text'>{item.project.title}</p></div>
                    <div className='grid-cell' style={{ flex: '1' }}><p className='grid-text'>{item.friendlydate}</p></div>
                    <div className='grid-cell' style={{ flex: '1' }}><p className='grid-text'>{Helpers.FormatDollars(item.amount)}</p></div>
                    <div className='grid-cell' style={{ flex: '1' }}><p className='grid-text'>{item.title}</p></div>
                    <ActionCell item={item} pastInvoices={this.state.pastInvoices} onUpdated={list => this.setPastInvoices(list)} />
                  </div>
                ))
              }
              <div style={{ borderTop: '1px solid #000000' }}></div>
            </div>
          </ConditionalDOM>
          <ConditionalDOM render={this.state.showForm}>
            <div className='content-card singular-card' style={{ width: '800px', marginBottom: '3em' }}>
              {/* IF TIME LOGS */}
              <ConditionalDOM render={this.state.invoiceTimeLogs.length > 0}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                  <div>
                    <p style={{ fontWeight: 'bold' }}>{`Loaded ${this.state.invoiceTimeLogs.length} time logs for this invoice.`}</p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <BaseButton content='Generate Invoice Description' classes='temp-short-button' onClick={this.sendNotesToInvoice} />
                  </div>
                </div>
                <div className='grid-row'>
                  <div className='grid-cell' style={{ width: '80px' }}><p className='grid-header'>Initials</p></div>
                  <div className='grid-cell' style={{ width: '150px' }}><p className='grid-header'>Date</p></div>
                  <div className='grid-cell' style={{ width: '120px' }}><p className='grid-header'>Hours</p></div>
                  <div className='grid-cell' style={{ width: '500px' }}><p className='grid-header'>Description</p></div>
                </div>
                {
                  this.state.invoiceTimeLogs.map((item, i) => (
                    <div key={'item' + item.idtimelog} className='grid-row item-hover' onClick={() => this.timeLogClicked(item)}>
                      <div className='grid-cell' style={{ width: '80px' }}><p className='grid-text'>{item.initials}</p></div>
                      <div className='grid-cell' style={{ width: '150px' }}><p className='grid-text'>{item.friendlydate}</p></div>
                      <div className='grid-cell' style={{ width: '120px' }}><p className='grid-text'>{Helpers.ConvertSecToHrs(item.duration)}</p></div>
                      <div className='grid-cell' style={{ width: '500px' }}><p className='grid-text'>{item.notes}</p></div>
                    </div>
                  ))
                }
                <div style={{ borderTop: '1px solid #000000' }}></div>
              </ConditionalDOM>
              <ConditionalDOM render={!this.state.invoice.isreimbursables && this.state.invoiceTimeLogs.length < 1}>
                <BaseButton content='Load Time Logs' classes='temp-short-button' onClick={this.loadTimeLogList} />
              </ConditionalDOM>
              {/* IF REIMBURSABLES */}
              <ConditionalDOM render={this.state.invoiceReimbursables.length > 0}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 0px 10px 0px' }}>
                  <div>
                    <p style={{ fontWeight: 'bold' }}>{`Loaded ${this.state.invoiceReimbursables.length} reimbursables for this invoice.`}</p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <BaseButton content='Generate Invoice Description' classes='temp-short-button' onClick={this.sendReimbursableDescriptionsToInvoice} />
                  </div>
                </div>
                <div className='grid-row'>
                  <div className='grid-cell' style={{ width: '80px' }}><p className='grid-header'>Initials</p></div>
                  <div className='grid-cell' style={{ width: '150px' }}><p className='grid-header'>Date</p></div>
                  <div className='grid-cell' style={{ width: '120px' }}><p className='grid-header'>Amount</p></div>
                  <div className='grid-cell' style={{ width: '500px' }}><p className='grid-header'>Description</p></div>
                </div>
                {
                  this.state.invoiceReimbursables.map((item, i) => (
                    <div key={'item' + item.idreimbursable} className='grid-row item-hover' onClick={() => this.reimbursableClicked(item)}>
                      <div className='grid-cell' style={{ width: '80px' }}><p className='grid-text'>{item.initials}</p></div>
                      <div className='grid-cell' style={{ width: '150px' }}><p className='grid-text'>{item.friendlydate}</p></div>
                      <div className='grid-cell' style={{ width: '120px' }}><p className='grid-text'>{Helpers.FormatDollars(item.amount)}</p></div>
                      <div className='grid-cell' style={{ width: '500px' }}><p className='grid-text'>{item.description}</p></div>
                    </div>
                  ))
                }
                <div style={{ borderTop: '1px solid #000000' }}></div>
              </ConditionalDOM>
              <ConditionalDOM render={this.state.invoice.isreimbursables && this.state.invoiceReimbursables.length < 1}>
                <BaseButton content='Load Reimbursables' classes='temp-short-button' onClick={this.loadReimbursablesList} />
              </ConditionalDOM>
              <Row>
                <Col xs='12' lg='6'>
                  <BaseInput name='invoice:title' startingValue={this.state.invoice.title} changeHandler={this.handleUpdates} label='Invoice Period' type='text' />
                </Col>
                <Col xs='12' lg='6'>
                  <BaseInput name='invoice:amount' startingValue={Helpers.FormatDollars(this.state.invoiceAmount)} changeHandler={this.handleUpdates} label='Amount' type='text' />
                </Col>
              </Row>
              <BaseInput name='invoice:description' startingValue={this.state.invoiceDescription} classes='invoice-description-input' changeHandler={this.handleUpdates} label='Invoice Description' type='textarea' />
              <Row>
                <Col xs='12' lg='6'>
                  <BaseButton content='Save Invoice' onClick={this.saveInvoiceClicked} />
                </Col>
                <Col xs='12' lg='6'>
                  <BaseButton content='Delete Invoice' classes='delete-button' onClick={this.deleteInvoiceClicked} />
                </Col>
              </Row>
            </div>
          </ConditionalDOM>
        </div>

        {/* EDITORS */}
        <ReimbursableEditor reimbursable={this.state.selectedReimbursable} unselect={this.unselectReimbursable} />
        <TimeLogEditor timeLog={this.state.selectedTimeLog} showBillableAmount={true} unselect={this.unselectTimeLog} reloadCb={this.reloadList} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session,
    projects: state.projects.list,
    users: state.user.list
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setProjectList: (arr) => dispatch(actions.setProjectList(arr)),
    setUserList: (arr) => dispatch(actions.setUserList(arr))
  }
}

ManageInvoices.propTypes = {
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageInvoices);